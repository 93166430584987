import damage from "./damage.png";
import claws from "./claws.png";
import advent from "./advent.png";
import aboutSquare from "./about_square.png";
import aboutRect from "../../static/me-small.png";

export const whatsNewSections = [
  {
    orientation: "left",
    title: "On the blog",
    text: `
Wax can get damaged so easily! You’re focussed on one point and before you know it your wax is damaged somewhere else. 

In the newest blog post you can read tips on how to protect your wax from accidental damage!`,
    link: `/blog/post/protect-your-wax`,
    linktext: `Read protect your wax`,
    image: damage,
    imagealt: `Close up of bench with a gold wax ring with multiple places it's damaged`,
  },

  {
    orientation: "right",
    title: `Behind the scenes`,
    text: `
I’m finally ready to get started on the claw setting classes. 

That’s right! Multiple. 

I have so many ideas with different difficulty levels that I can make 2! 
`,
    image: claws,
    imagealt:
      "White paper with writing mapping out what is planned for a class. Various gemstones are lying on top",
  },

  {
    orientation: "left",
    title: `With the classes`,
    text: `
It’s baaaaaack!

The advent calendars are back for sale for the rest of the year! To celebrate you get a 25th bonus class if you buy an advent calendar in September!

The advent calendars are good if you want to learn a little bit about a lot of different topics! Hard wax, soft wax, melting, carving, it’s all included!
`,
    link: `advent-calendar`,
    linktext: `Show me the advent calendars!`,
    image: advent,
    imagealt: "Benchpeg with a green wax and blue wax Christmas tree on top",
  },
];

export const blurbsection = {
  text: `Wax carving is a fun jewellery making technique where you transform an unassuming piece of wax into a beautiful piece of jewellery! Learn to carve rings,  3-dimensional shapes, classic pieces, and bold statement jewellery. 

Anything is possible in wax!

Through video tutorials you learn to carve your own piece of jewellery ready to be cast in the metal of your choice. Want to know what you can make?`,
  linktext: `Yes! Show me the classes!`,
  link: `/classes`,
};

export const aboutsection = {
  title: `Hi I'm Sandy!`,
  text: `I’m the CEO (Chief EVERYTHING Officer) here at Wax Carvers.
  
I did my first jewellery making class in 2012 and haven’t looked back since. I fell in love with wax carving in 2018 and I’m excited to share that love with you!
`,
  linktext: `Want to know more?`,
  link: `/about`,
  squarephoto: aboutSquare,
  rectphoto: aboutRect,
};

export const blogsection = {
  text: `
Do you want to know what tools you need to get started with wax carving?

Do you want to know what wax color you should get?

Do you want to know how you can set up a quick bench when you don’t have space for a dedicated one?

All these topics and more are covered on the blog! A new post is published every other Tuesday. So you can keep coming back to learn more about wax carving.
`,
  linktext: `Let's read that blog!`,
  link: `/blog`,
};
